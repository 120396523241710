import React from 'react';
import PropTypes from 'prop-types';

const Figure = ({ children, caption }) => {
  return (
    <figure className="figure">
      {children && children}
      {caption && <figcaption className="figure__caption">{caption}</figcaption>}
    </figure>
  );
};

Figure.propTypes = {
  children: PropTypes.node.isRequired,
  caption: PropTypes.string,
};

Figure.defaultProps = {
  caption: null,
};

export default Figure;
