import React from 'react';
import SocialMedia from '../atoms/social-media';

function getUrl(fallbackUrl, devUrl = 'http://localhost:8000') {
  const { href: windowUrl } = window && window.location;

  if (!windowUrl || windowUrl.includes(devUrl)) {
    return fallbackUrl;
  }

  return windowUrl;
}

function facebookUrl(url, title) {
  return `https://www.facebook.com/sharer/sharer.php?u=${url}&t=${title}`;
}

function twitterUrl(url, title, via) {
  return `https://twitter.com/share?url=${url}&text=${title}&via=${via}`;
}

function linkedInUrl(url, title) {
  return `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}`;
}

function shareButtonClick(e, url, width = 600, height = 300) {
  const options = 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,';
  window.open(url, '', `${options}height=${height},width=${width}`);
  e.preventDefault();
}

const SocialMediaShare = ({ fallbackUrl, label, pageTitle, twitterUsername, devUrl }) => {
  const url = encodeURI(getUrl(fallbackUrl, devUrl));
  const encodedTitle = encodeURI(pageTitle);

  const items = [
    {
      network: 'Twitter',
      url: twitterUrl(url, encodedTitle, twitterUsername),
    },
    {
      network: 'Facebook',
      url: facebookUrl(url, encodedTitle),
    },
    {
      network: 'LinkedIn',
      url: linkedInUrl(url, encodedTitle),
    },
  ];

  return (
    <section className="social-media-share">
      <strong className="social-media-share__title">{label}</strong>
      <SocialMedia className="social-media-share__list" items={items} click={shareButtonClick} buttons />
    </section>
  );
};

SocialMediaShare.defaultProps = {
  label: 'Share this post',
  twitterUsername: 'liminal_pages',
  devUrl: 'http://localhost:8000',
};

export default SocialMediaShare;
