import React from 'react';

const Article = ({ renderHeader, renderBody }) => (
  <article className="article">
    <div className="article__inner container">
      {renderHeader()}
      <div className="article__body">{renderBody()}</div>
    </div>
  </article>
);

export default Article;
