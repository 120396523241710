import React from 'react';
import PropTypes from 'prop-types';

const Video = ({ src, ...rest }) => {
  if (src) {
    return (
      <span className="video">
        <iframe className="video__media" src={src} {...rest}></iframe>
      </span>
    );
  }

  return null;
};

Video.propTypes = {
  src: PropTypes.string.isRequired,
};

export default Video;
