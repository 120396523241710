import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ReactHtmlParser from 'react-html-parser';
import generatePropsFromAttributes from 'react-html-parser/lib/utils/generatePropsFromAttributes';
import { transformScript, transformIframe, transformImage, transformFigure } from '../../utils/transformers';

const BlogContent = ({ body }) => {
  const { allImages } = useStaticQuery(graphql`
    query {
      allImages: allWordpressWpMedia {
        nodes {
          sourceUrl: source_url
          alt: alt_text
          localFile {
            publicURL
          }
        }
      }
    }
  `);

  const { nodes: allMedia } = allImages;

  const transform = (node, index) => {
    const { type, name, attribs, children } = node;
    const elementProps = attribs ? generatePropsFromAttributes(attribs, index) : {};

    if (type === 'script') {
      return transformScript(attribs.src);
    }

    if (type === 'tag' && name === 'iframe') {
      return transformIframe(elementProps);
    }

    if (type === 'tag' && name === 'img') {
      return transformImage(node, attribs, allMedia);
    }

    if (type === 'tag' && name === 'figure') {
      return transformFigure(children, (n, i) => transform(n, i, allMedia));
    }
  };

  const parsedBody = ReactHtmlParser(body, {
    transform: (node, index) => transform(node, index, allMedia),
  });

  return (
    <section className="blog-content">
      <div className="blog-content__body">{parsedBody}</div>
    </section>
  );
};

export default BlogContent;
