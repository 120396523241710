import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { getTaxonomyUrl } from '../../utils/urls';
import getGraphqlImage from '../../utils/get-graphql-image';

const BlogHeader = ({ title, image, date, author, rootUrl, taxonomy, categoryName, categorySlug }) => {
  const categoryUrl = getTaxonomyUrl(rootUrl, taxonomy, categorySlug);
  const imageSizes = getGraphqlImage(image);

  return (
    <div className="blog-header">
      <header className="blog-header__header">
        <h1 className="blog-header__title" dangerouslySetInnerHTML={{ __html: title }} />
        <div className="blog-header__categories">
          <Link className="blog-header__category" to={categoryUrl}>
            {categoryName}
          </Link>
        </div>
        <div className="blog-header__meta">
          <time className="blog-header__meta-item">{date}</time>
          <span className="blog-header__meta-item">{author}</span>
        </div>
      </header>
      {image && (
        <div className="blog-header__image-wrapper">
          <Img alt={`${title} image`} className="blog-header__image" fluid={imageSizes} />
        </div>
      )}
    </div>
  );
};

BlogHeader.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
};

export default BlogHeader;
