import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

const RelatedContentCard = ({ title, slug, imageSrc, imageSizes }) => (
  <article className="related-content-card">
    <Link className="related-content-card__link" to={`/${slug}`}>
      <strong className="related-content-card__title" dangerouslySetInnerHTML={{ __html: title }} />
      {imageSrc && imageSizes && (
        <Img alt={`${title} image`} className="related-content-card__image" src={imageSrc} fluid={imageSizes} />
      )}
    </Link>
  </article>
);

export default RelatedContentCard;
