import React from 'react';
import { processNodes } from 'react-html-parser';
import classNames from 'classnames';
import Figure from '../components/atoms/figure';
import Video from '../components/atoms/video';
import loadThirdPartyApi from './load-third-party-api';
import { TWITTER_WIDGETS_URL, INSTAGRAM_EMBED_URL } from './constants';

export function transformScript(src) {
  const scripts = [
    {
      id: 'twitterApi',
      src: TWITTER_WIDGETS_URL,
    },
    {
      id: 'instagramApi',
      src: INSTAGRAM_EMBED_URL,
    },
  ];

  if (src) {
    for (let i = 0; i < scripts.length; i += 1) {
      const item = scripts[i];

      if (src === item.src) {
        loadThirdPartyApi(item.id, item.src);
      }
    }
  }

  return null;
}

export function transformIframe(props) {
  const { src, width, height, ...rest } = props;

  if (src && (src.includes('youtube.com') || src.includes('vimeo.com'))) {
    return <Video src={src} {...rest} />;
  }

  return null;
}

function getImageData(imageLibrary, node) {
  if (node.attribs && node.attribs.src) {
    const { src } = node.attribs;
    const libraryImage = imageLibrary.find(item => item.sourceUrl === src);

    if (libraryImage && libraryImage.localFile && libraryImage.localFile.publicURL) {
      const { alt, localFile } = libraryImage;
      const { publicURL } = localFile;

      return {
        alt,
        src: publicURL,
      };
    }
  }

  return null;
}

export function transformImage(node, attribs, allMedia) {
  const { alt, src, ...imageData } = getImageData(allMedia, node);
  const { class: cssClassName } = attribs;

  const align = cssClassName && cssClassName.split(' ').filter(str => str.includes('align'));
  const alignDirection = align.length > 0 ? align.map(item => item.substring(item.indexOf('n') + 1))[0] : null;

  if (src) {
    return (
      <img
        alt={alt}
        className={classNames({
          [`align-${alignDirection}`]: alignDirection,
        })}
        src={src}
        {...imageData}
      />
    );
  }

  return null;
}

export function transformFigure(children, callBack) {
  if (children) {
    const captionIndex = children.findIndex(
      ({ type: typeName, name: tagName }) => typeName === 'tag' && tagName === 'figcaption'
    );

    const captionNode = captionIndex !== -1 ? children[captionIndex] : null;

    const caption =
      captionNode && captionNode.children && captionNode.children[0] && captionNode.children[0].data
        ? captionNode.children[0].data
        : null;

    const childElements = captionIndex !== -1 ? children.filter((item, index) => index !== captionIndex) : children;

    const renderChildren = processNodes(childElements, (n, i) => callBack(n, i));

    return <Figure caption={caption}>{renderChildren}</Figure>;
  }

  return null;
}
