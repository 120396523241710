import React from 'react';
import PropTypes from 'prop-types';
import RelatedContentCard from '../atoms/related-content-card';

const RelatedContent = ({ title, items }) => (
  <section className="related-content">
    <strong className="related-content__title">{title}</strong>
    <ul className="related-content__items">
      {items.map(({ node }) => {
        const { slug, featured_media: featuredMedia, title: postTitle, wordpress_id: id } = node;
        const imageSrc = featuredMedia && featuredMedia.localFile.childImageSharp.sizes.src;
        const imageSizes = featuredMedia && featuredMedia.localFile.childImageSharp.sizes;

        return (
          <li className="related-content__item" key={id}>
            <RelatedContentCard slug={slug} title={postTitle} imageSrc={imageSrc} imageSizes={imageSizes} />
          </li>
        );
      })}
    </ul>
  </section>
);

RelatedContent.defaultProps = {
  title: 'You might also like',
};

export default RelatedContent;
