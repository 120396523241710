import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import NoSSR from '@chuyik/react-no-ssr';
import Layout from '../components/layout';
import Seo from '../components/seo';
import BlogHeader from '../components/molecules/blog-header';
import BlogContent from '../components/molecules/blog-content';
import AuthorBio from '../components/molecules/author-bio';
import RelatedContent from '../components/molecules/related-content';
import FormRenderer from '../components/organisms/form-renderer';
import SocialMediaShare from '../components/molecules/social-media-share';
import Article from '../components/organisms/article';

const BlogPostTemplate = ({ data, pageContext }) => {
  const ogImage = data.page.featured_media && data.page.featured_media.ogImage;
  const twitterImage = data.page.featured_media && data.page.featured_media.twitterImage;

  return (
    <Layout>
      <Seo
        fallbackTitle={data.page.title}
        title={data.page.yoast.title}
        description={data.page.yoast.metadesc}
        ogImage={ogImage}
        twitterImage={twitterImage}
      />
      <Article
        renderHeader={() => (
          <BlogHeader
            title={data.page.title}
            image={data.page.featured_media}
            date={data.page.date}
            author={data.page.author.name}
            rootUrl="/blog"
            taxonomy="category"
            categoryName={data.page.categories[0].name}
            categorySlug={data.page.categories[0].slug}
          />
        )}
        renderBody={() => (
          <Fragment>
            <BlogContent body={data.page.content} />
            <NoSSR>
              <SocialMediaShare pageTitle={data.page.title} fallbackUrl={process.env.URL} />
            </NoSSR>
            <AuthorBio
              imageSrc={data.page.author.acf.biographyImage.localFile.childImageSharp.sizes.src}
              imageSizes={data.page.author.acf.biographyImage.localFile.childImageSharp.sizes}
              name={data.page.author.name}
              biography={data.page.author.description}
            />
            {pageContext.relatedPosts && pageContext.relatedPosts.length > 0 && (
              <RelatedContent items={pageContext.relatedPosts} />
            )}
          </Fragment>
        )}
      />

      <FormRenderer form={{ wordpress_id: data.form.wordpress_id, ...data.form.acf }} />
    </Layout>
  );
};

export default BlogPostTemplate;

export const query = graphql`
  query($id: Int!) {
    page: wordpressPost(wordpress_id: { eq: $id }) {
      yoast {
        title
        metadesc
      }
      title
      content
      excerpt
      date(formatString: "Do MMMM YYYY")
      author {
        name
        description
        acf {
          biographyImage {
            localFile {
              childImageSharp {
                sizes(maxWidth: 780) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
      featured_media {
        localFile {
          childImageSharp {
            sizes(maxWidth: 780) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        ogImage: localFile {
          childImageSharp {
            resize(width: 1200, height: 630) {
              src
            }
          }
        }
        twitterImage: localFile {
          childImageSharp {
            resize(width: 1000, height: 1000) {
              src
            }
          }
        }
      }
      categories {
        name
        slug
      }
    }
    form: wordpressWpForm(wordpress_id: { eq: 230 }) {
      wordpress_id
      acf {
        title
        subtitle
        introduction
        cta_button_text
        note
        mailchimp_url
        mailchimp_user_id
        mailchimp_list_id
        success_message
        generic_error_message
        already_subscribed_error_message
        background_image {
          localFile {
            childImageSharp {
              sizes(maxWidth: 640) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
      }
    }
  }
`;
