import React from 'react';
import uniqid from 'uniqid';

import Img from 'gatsby-image';
import ReactStringReplace from 'react-string-replace';
import Link from 'gatsby';
import PropTypes from 'prop-types';

const AuthorBio = ({ name, biography, imageSrc, imageSizes, domain }) => {
  const parsedBiography = ReactStringReplace(biography, '[url]', () => (
    <a href="/" key={uniqid()}>
      {domain}
    </a>
  ));

  return (
    <section className="author-bio">
      <div className="author-bio__inner">
        <Img alt={`${name} profile picture`} className="author-bio__image" src={imageSrc} fluid={imageSizes} />
        <div className="author-bio__body">
          <span className="author-bio__author">{name}</span>
          <span className="author-bio__content">{parsedBiography}</span>
        </div>
      </div>
    </section>
  );
};

AuthorBio.defaultProps = {
  domain: 'liminalpages.com',
};

export default AuthorBio;
